// Need this for webpack to find calendar styles
@import "~react-big-calendar/lib/sass/styles";
@import "~react-big-calendar/lib/addons/dragAndDrop/styles";

// Hide times in event blocks
.rbc-event-label {
  display: none;
}

// Center event names in blocks
.rbc-event-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

// Hide all-day event cells
.rbc-allday-cell {
  display: none;
}

// Format day headers
.rbc-header {
  font-size: 110%;
  padding: 0.6ch 0ch;
}

.rbc-header > a {
  cursor: default;
}

.rbc-time-content {
  overflow: hidden;
}
